import css from './Offer.module.scss'
import global from '../../../styles/Global.module.scss'
import {useDispatch} from "react-redux";
import {types} from "../../../actions/feedback";
import {useEffect, useRef, useState} from "react";
import Image from 'next/image'
import useVisible from "../../../hooks/useVisible";

export default function Offer ({data}) {

    //animation
    const ref = useRef(null)
    const {isVisible: inView} = useVisible(ref)
    //

    const dispatch = useDispatch()

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        inView && !visible && setVisible(true)
    }, [inView])

    return (
        <section className={`${css.parking} ${global.section}`} ref={ref}>
            <div className={inView ? `${css.content} ${css.animation}` : `${css.content}`}>
                {visible && <div className={`${css.item}`}>
                    <img
                        src={process.env.NEXT_PUBLIC_API_LOCAL_PATH ? `${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${data.image}` : `https://my-priority.ddplanet.ru${data.image}`}
                        alt="Offer"
                        layout={"fill"}
                        className={css.img}
                    />
                </div>}
                <div className={`${css.item} ${css.text}`}>
                    <h2 className={css.title}>{data.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: data.text}}></p>
                    <button onClick={() => dispatch({type: types.SET_FEEDBACK_POPUP, payload: {type:'offer'}})}
                            className={css.button}>{data.button_text}</button>
                </div>
            </div>
        </section>
    )
}